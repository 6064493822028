<template>
  <figure
    class="VideoLoop"
    :class="[
      isPlaying && 'VideoLoop_playing',
      isPortrait && 'VideoLoop_portrait',
    ]"
  >
    <video
      ref="videoEl"
      class="VideoLoop_Video"
      muted
      playsinline
      disablePictureInPicture
      loop
      preload="true"
      :width="videoWidth"
      :height="videoHeight"
      crossorigin="anonymous"
      @canplay="updatePaused"
      @playing="updatePaused"
      @pause="updatePaused"
    ></video>
    <ElementsMediaBaseImage
      v-if="props.posterImage"
      :image="props.posterImage"
      :alt="props.video?.alt"
    />
    <img
      v-else
      :src="posterSrc"
      :width="videoWidth"
      :height="videoHeight"
      :alt="props.video?.alt"
    />
  </figure>
</template>

<script setup>
const props = defineProps({
  /* 
	Object containing a muxVideo. 
	*/
  video: {
    type: Object,
    default: () => undefined,
  },

  /* 
	Without a posterimage the first frame from the mux video is used.
	*/
  posterImage: {
    type: Object,
    default: () => undefined,
  },

  /* 
	Use mp4 instead of hls.
	*/
  preferMp4: {
    type: Boolean,
    default: () => true,
  },

  /* 
	Should the video load a smaller mp4 version from mux (only applicable if preferMp4 is true).
	*/
  useSmallResolution: {
    type: Boolean,
    default: () => false,
  },

  /* 
	Defines if the video should preload. If false the video loads when becoming visible.
	Loading starts as soon as preload is set to true.
	*/
  preload: {
    type: Boolean,
    default: () => false,
  },
})

/*
variables
*/
const videoEl = ref(null)
const isPlaying = ref(false)
const isLoaded = ref(false)
const isInit = ref(false)

/*
dimensions
*/
const videoWidth = computed(() => {
  const videoTrack = props.video?.muxVideo?.asset?.data?.tracks?.find(
    (el) => el.type === 'video'
  )
  return videoTrack ? videoTrack.max_width : undefined
})
const videoHeight = computed(() => {
  const videoTrack = props.video?.muxVideo?.asset?.data?.tracks?.find(
    (el) => el.type === 'video'
  )
  return videoTrack ? videoTrack.max_height : undefined
})
const isPortrait = computed(() => videoWidth.value < videoHeight.value)
const aspectRatioStyle = computed(
  () => `${videoWidth.value} / ${videoHeight.value}`
)

/*
poster image
*/
const posterSrc = computed(() => {
  return props.video?.muxVideo?.asset?.playbackId
    ? `https://image.mux.com/${props.video?.muxVideo?.asset?.playbackId}/thumbnail.jpg?time=0`
    : ''
})

/*
setup
*/
const emit = defineEmits(['loaded'])
const { appendVideo } = useMuxStream({
  muxVideo: props.video?.muxVideo,
  videoEl: videoEl,
  preferMp4: props.preferMp4,
  useSmallResolution: props.useSmallResolution,
})

/*
load video
*/
const loadVideo = () => {
  if (!isInit.value) {
    appendVideo()
    videoEl?.value?.load()
    isInit.value = true
  }
}

/*
handle play state
*/
const updatePaused = (event) => {
  isPlaying.value =
    !event.target.paused &&
    !event.target.ended &&
    event.target.readyState > event.target.HAVE_CURRENT_DATA
  // set loading state
  if (!isLoaded.value) {
    isLoaded.value = true
    emit('loaded')
  }
}

/*
play promise
*/
const playVideo = () => {
  // use promise to avoid play interruption
  const playPromise = videoEl?.value?.play()

  if (playPromise !== undefined) {
    playPromise.catch(function (error) {
      // Automatic playback failed
    })
  } else {
    videoEl?.value?.play()
  }
}

/*
play only when visible
*/
const { stop } = useIntersectionObserver(
  videoEl,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting) {
      loadVideo()
      playVideo()
    } else {
      setTimeout(() => {
        loadVideo()
        playVideo()
        // videoEl?.value?.pause()
      }, 100)
    }
  },
  {
    threshold: 0.0,
  }
)

/*
preload
*/
const { trigger: triggerPreload } = watchTriggerable(
  () => props.preload,
  (preload) => {
    if (preload) {
      loadVideo()
    }
  }
)

/*
load directly if preload is true
*/
onMounted(() => {
  triggerPreload()
})
</script>

<style scoped>
/* .VideoLoop {
	position: relative;
}

.VideoLoop > video {
	position: relative;
	aspect-ratio: v-bind('aspectRatioStyle');
	z-index: 2;
}

.VideoLoop:deep(img) {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
}

.VideoLoop_playing:deep(img) {
	display: none;
} */

.VideoLoop {
  position: relative;
  height: auto;
  line-height: 0;
  overflow: hidden;
}

.VideoLoop:deep(img) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  object-fit: contain;
  width: 100%;
  height: auto;
}

.VideoLoop_playing:deep(img) {
  display: none;
}
/*
video
*/

.VideoLoop_Video {
  position: relative;
  width: 100%;
  height: auto;
}

.VideoLoop_initial .VideoLoop_Video {
  object-fit: contain;
  background: none;
}

.VideoLoop:fullscreen,
.VideoLoop:fullscreen .VideoLoop_Video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: rgb(var(--clr-black));
}

/*
cover
*/

.VideoLoop_Cover {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s ease;
}

.VideoLoop_initial .VideoLoop_Cover {
  opacity: 1;
  cursor: pointer;
}

.VideoLoop_Cover:deep(.PlayIcon) {
  width: 5rem;
  transition: transform 0.4s;
}

@media (hover: hover) and (pointer: fine) {
  .VideoLoop_Cover:hover:deep(.PlayIcon) {
    transform: scale(1.2);
  }
}

/*
loop cover
*/

.VideoLoop_PreviewLoop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  opacity: 0;
}

.VideoLoop_initial .VideoLoop_PreviewLoop {
  opacity: 1;
}

/*
controls
*/

.VideoLoop_Controls {
  position: absolute;
  display: flex;
  height: 30px;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  z-index: 2;
  list-style: none;
  transition: opacity 0.2s 1s ease;
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.003) 8.1%,
    hsla(0, 0%, 0%, 0.01) 15.5%,
    hsla(0, 0%, 0%, 0.021) 22.5%,
    hsla(0, 0%, 0%, 0.035) 29%,
    hsla(0, 0%, 0%, 0.052) 35.3%,
    hsla(0, 0%, 0%, 0.07) 41.2%,
    hsla(0, 0%, 0%, 0.09) 47.1%,
    hsla(0, 0%, 0%, 0.11) 52.9%,
    hsla(0, 0%, 0%, 0.13) 58.8%,
    hsla(0, 0%, 0%, 0.148) 64.7%,
    hsla(0, 0%, 0%, 0.165) 71%,
    hsla(0, 0%, 0%, 0.179) 77.5%,
    hsla(0, 0%, 0%, 0.19) 84.5%,
    hsla(0, 0%, 0%, 0.197) 91.9%,
    hsla(0, 0%, 0%, 0.2) 100%
  );

  @media (min-width: token(width.md)) {
    height: 50px;
  }
}

.VideoLoop_playing .VideoLoop_Controls {
  opacity: 0;
}

@media (hover: hover) and (pointer: fine) {
  .VideoLoop:hover .VideoLoop_Controls {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
}

@media (hover: none) and (pointer: coarse) {
  .VideoLoop_playing .VideoLoop_Controls {
    opacity: 1;
  }
}

/* buttons */

.VideoLoop_ControlsButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  padding: 0 10px;

  @media (min-width: token(width.md)) {
    width: auto;
    padding: 0 20px;
  }
}

.VideoLoop_ControlsButton > span {
  display: inline-block;
  overflow: hidden;
  line-height: 0;
}

@media (hover: hover) and (pointer: fine) {
  .VideoLoop_ControlsButton:hover {
    opacity: 0.5;
  }
}
</style>
