<template>
  <figure>
    <ElementsMediaBaseImage
      :image="props.image"
      :auto="props.auto"
      :fit="props.fit"
      :use-hotspot="props.useHotspot"
    />
    <figcaption v-if="props.image?.caption" class="text-sm">
      {{ props.image.caption }}
    </figcaption>
  </figure>
</template>

<script setup>
const props = defineProps({
  image: {
    type: Object,
    default: () => undefined,
  },
  auto: {
    default: 'format',
    type: String,
  },
  fit: {
    default: 'max',
    type: String,
  },
  posterImage: {
    type: Object,
  },
  useHotspot: {
    type: Boolean,
    default: () => false,
  },
})

const { $urlFor } = useNuxtApp()
const posterSrc = computed(() => {
  if (props.posterImage) {
    return $urlFor(props.posterImage)
      .width(videoWidth.value ?? 1920)
      .url()
  } else {
    return props.video?.muxVideo?.asset?.playbackId
      ? `https://image.mux.com/${
          props.video?.muxVideo?.asset?.playbackId
        }/thumbnail.jpg?time=${props.video?.muxVideo?.asset?.thumbTime ?? 0}`
      : ''
  }
})
</script>
